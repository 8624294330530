/* eslint-disable import/extensions */
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { AppContext } from '@powdr/context';
import { componentFactory } from '@powdr/utils';
import WrapperlessStyle from '@powdr/web/src/utils/wrapperless-styles';

const SiteWrapper = styled.div``;
const StyledInnerPage = styled.div``;
const StyledMain = styled.main``;

function WrapperlessLayout({ data }) {
  const currentPage = data.pageContent.nodes?.[0];
  const {
    defaultTheme,
  } = useContext(AppContext);

  return (
    <>
      <WrapperlessStyle theme={defaultTheme} />
      <SiteWrapper>
        <StyledMain>
          <StyledInnerPage>
            {componentFactory(currentPage?.relationships.pageContent || [], undefined, undefined, {
              isTransparent: true,
            })}
          </StyledInnerPage>
        </StyledMain>
      </SiteWrapper>
    </>
  );
}

WrapperlessLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

WrapperlessLayout.defaultProps = {

};

export default WrapperlessLayout;

export const query = graphql`
  query WrapperlessLayout($fullPath: String) {
    site {
      ...SiteMetadataQuery
    }
    pageContent: allNodePage(
      filter: { field_page_location: { eq: $fullPath }, status: { eq: true } }
    ) {
      ...NodePageQuery
    }
    allTaxonomyTermFooterLinks {
      ...TaxonomyTermFooterLinksQuery
    }
    allTaxonomyTermPartners {
      ...TaxonomyTermPartnersQuery
    }
    paragraphHeaderConfiguration {
      ...HeaderConfigurationQuery
    }
    nestedNavigation: taxonomyTermSiteNavigation {
      ...NestedSiteNavigationQuery
    }
    pageSettings: allTaxonomyTermSiteNavigation(filter: { field_navigation_link: { eq: $fullPath } }) {
      nodes {
        ...NavigationItemQuery
      }
    }
  }
`;
