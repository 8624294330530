import { createGlobalStyle } from 'styled-components';

import animations from '../styles/animations';
import reset from '../styles/reset';
import typography from '../styles/typography';
import utils from '../styles/utils';
import wrapperless from '../styles/wrapperless';

const WrapperlessStyle = createGlobalStyle`
  ${reset}
  ${typography}
  ${wrapperless}
  ${utils}
  ${animations}
  ${(props) => props.theme.FontFaces}
  ${(props) => props.theme.fonts}
`;

export default WrapperlessStyle;
