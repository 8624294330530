import { css } from 'styled-components';

import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';
// TODO: Convert all @includes from the `powdr-ui` core scss `client/assets/core/scss/_core.scss`
const wrapperless = css`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    white-space: normal;
    &:focus {
      outline: 0;
    }
  }

  body {
    /* *font-size:small; */
    /* *font:x-small; */
    line-height: 1;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: transparent;
    color: ${({ theme }) => theme.defaultTheme[ColorProfiles.BASE][Prop.CONTENT]};
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
  }


  input,textarea {
    @include user-select(auto);
    @include bp-mobile {
      @include user-select(text);
    }
  }

  a {
    /* @include transition(color .25s ease-out, background-color .25s ease-out); */
    cursor: pointer;
    text-decoration: none;
    /* @include bp-mobile-tablet {
      @include transition(none);
    } */
  }

  button {
    /* @include border-radius(0); */
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    overflow: visible;
  }

  nav {
    position: relative;
  }

  figure, li {
    position: relative;
  }

  cite {
    display: block;
  }

  .bullet-points {
    list-style: disc outside none;
    margin-left: 15px;
    margin-bottom: 5px;
  }

  img {
    max-width: 100%;
  }
`;

export default wrapperless;
